import React from "react";
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from "../components/Layout";
import { TitleContainer, Title } from "../components/atoms";
import { News as Articles } from "../components/organisms";

const NewsContainer = styled.article`
    margin: 0 0 4rem;
`

const News = ({ data: { latestNews } }) => {
    return (
        <Layout>
            <main>
                <header>
                    <TitleContainer maxWidth="73.125em" gutter>
                        <Title as="h1" noMargin>News</Title>
                    </TitleContainer>
                </header>
                <NewsContainer>
                    {!!latestNews.nodes.length && (
                        <Articles articles={latestNews.nodes} />
                    )}
                </NewsContainer>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query NewsQuery {
        latestNews: allWpPost(limit: 5, sort: {fields: date, order: DESC}) {
            nodes {
                id
                title
                uri
                excerpt
                teams {
                    nodes {
                        id
                        uri
                        customFields{
                            abbreviation
                        }
                    }
                }
                landscapeImage: hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
            }
        }
    }
`

export default News